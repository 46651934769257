import type { CompanyUsersTableRowData, ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import { NameWithIcon } from '@energywebfoundation/generic-green-proofs-ui';
import {
  GENERIC_EMAIL_COLUMN_SIZE,
  GENERIC_NAME_COLUMN_SIZE,
} from '../column.sizes';
import type { User } from '../../../entities/user/types';
import { extractUserName } from '../../../entities/user/utils';

export const companyUsers: ColumnDefinition<CompanyUsersTableRowData<User>>[] = [
  {
    accessorKey: 'name',
    header: 'User name',
    size: GENERIC_NAME_COLUMN_SIZE,
    Cell: ({ row }) => <NameWithIcon name={extractUserName(row.original.data)} />,
    filterFn: (row, _, filterValue) => {
      return extractUserName(row.original.data).toLowerCase().includes(filterValue.toLowerCase());
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    size: GENERIC_EMAIL_COLUMN_SIZE,
    Cell: ({ row }) => {
      return row.original.data.email;
    },
  },
];
