import { getLastPartOfUUID, UseIcon } from '@energywebfoundation/generic-green-proofs-ui';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { CSSProperties, FC, ReactNode } from 'react';
import { BlockLink, BlockText, StyledTooltip } from './BlockId.styles';

interface BlockIdProps {
  blockId: string;
  voting?: {
    status: string;
    explorerUrls: string[];
  };
  href?: string;
}

const iconStyle: CSSProperties = {
  width: 16,
  height: 16,
};

const Icons: Record<string, ReactNode> = {
  approve: (
    <VerifiedIcon color="primary" sx={iconStyle} data-testid="node-verified" />
  ),
  reject: null,
  pending: null,
};

const TooltipContent: Record<
  string,
  (data: { explorerUrls: string[] }) => JSX.Element | null
> = {
  approve: ({ explorerUrls }) => (
    <Box px={1} py={1}>
      <Typography fontWeight={'bold'}>
        Unit was verified by EWX Worker Nodes
      </Typography>
      <br />
      <Typography>Votes:</Typography>
      <List
        dense
        sx={{
          maxHeight: 200,
          overflow: 'auto',
        }}
      >
        {explorerUrls.map((url, index) => {
          return (
            <ListItem dense key={index} alignItems="center">
              <ListItemIcon sx={{ ...iconStyle, minWidth: 'unset', mr: 2 }}>
                <HowToVoteIcon sx={iconStyle} color="primary" />
              </ListItemIcon>
              <ListItemText>
                {url ? (
                  <Link target="_blank" rel="noopener" href={url}>
                    Event #{index + 1}
                  </Link>
                ) : (
                  <Typography>Link Restricted</Typography>
                )}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Box>
  ),
  reject: () => null,
  pending: () => null,
};


/** This is unrestricted version of BlockId from GGP  */
export const UnrestrictedBlockId: FC<BlockIdProps> = ({ blockId, href, voting }) => {
  const status = voting?.status;;

  return (
    <Box display="flex" gap={1}>
      <Box display="flex" my="auto">
        <UseIcon
          icon="unitsBlockSvg"
          sx={{
            height: 20,
            width: 20,
          }}
        />
      </Box>
      {href ? (
        <BlockLink
          onClick={(e) => e.stopPropagation()}
          href={href}
          data-testid={`blockid-link-${blockId}`}
        >
          {getLastPartOfUUID(blockId)}
        </BlockLink>
      ) : (
        <BlockText data-testid={`blockid-text-${blockId}`}>
          {getLastPartOfUUID(blockId)}
        </BlockText>
      )}

      {voting && status && (
        <StyledTooltip
          title={TooltipContent[status]({ explorerUrls: voting.explorerUrls })}
        >
          <Box display="flex" my="auto">
            {Icons[status]}
          </Box>
        </StyledTooltip>
      )}
    </Box>
  );
};