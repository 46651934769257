import type { ProcessDTO } from '@energywebfoundation/generic-green-proofs-api-client';
import type { ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import { ProcessIdCell, RoutePaths } from '@energywebfoundation/generic-green-proofs-ui';
import Chip from '@mui/material/Chip';


export const pendingUnits: ColumnDefinition<ProcessDTO>[] = [
  {
    header: 'Id',
    Cell: ProcessIdCell,
  },
  {
    header: 'Type',
    accessorKey: 'computed.transactionType',
  },
  {
    accessorKey: 'computed.volume',
    header: 'Volume',
  },
  {
    accessorKey: 'computed.status',
    header: 'Status',
    Cell: ({ cell }) => {
      return <Chip
        sx={{
          backgroundColor: 'rgba(24, 128, 56, 0.20)',
          color: '#188038',
          paddingLeft: 0,
          textTransform: 'capitalize'
        }}
        label={`${cell.getValue<string>()}`}
      />;
    },
  },
];

const onRowClick = (navigate: (route: string) => void) => (row: ProcessDTO) =>
  navigate(RoutePaths.ACTION(row.id));

export const pendingUnitsTable = {
  columns: pendingUnits,
  onRowClick,
};
