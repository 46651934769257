import type { ColumnDefinition, LogsTableRowData } from '@energywebfoundation/generic-green-proofs-ui';
import {
  createTableHeaderTooltip
} from '@energywebfoundation/generic-green-proofs-ui';
import dayjs from 'dayjs';

export const logs: ColumnDefinition<LogsTableRowData>[] = [
  {
    accessorKey: 'description',
    Header: createTableHeaderTooltip('Event description'),
    header: 'Event',
  },
  {
    accessorKey: 'date',
    header: 'When',
    accessorFn: (row) => dayjs(row.date).format('DD-MM-YYYY HH:mm:ss'),
  },
];

