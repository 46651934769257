import type {
  ColumnDefinition,
  UnitsTableRowData,
} from '@energywebfoundation/generic-green-proofs-ui';
import {
  BlockIdCell,
  MaybeRestricted,
  MaybeRestrictedCell,
  UserStatusChip,
} from '@energywebfoundation/generic-green-proofs-ui';
import { Computed } from '../../../backend/unit-view/unit-view-builder';
import type { Unit } from '../../../entities/unit/types';

export const units: ColumnDefinition<UnitsTableRowData<Unit, Computed>>[] = [
  {
    accessorKey: 'id',
    header: 'Block Unit ID',
    Cell: BlockIdCell,
  },
  {
    accessorKey: 'volume',
    header: 'Amount',
  },
  {
    accessorKey: 'metadata.id',
    header: 'Certificate id',
    Cell: MaybeRestrictedCell,
  },
  {
    accessorKey: 'metadata.name',
    header: 'Certificate name',
    Cell: MaybeRestrictedCell,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => {
      return (
        <UserStatusChip
          status={cell.getValue<MaybeRestricted<string>>().toString() as any}
        />
      );
    },
  },
];
