import { GENERIC_EMAIL_COLUMN_SIZE } from '../column.sizes';
import { UserStatusChip, type AccountUsersTableRowData, type ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import type { User } from '../../../entities/user/types';
import { AccountRolesDetails } from '../../../entities/account/roles';
import { extractUserName } from '../../../entities/user/utils';

export const accountUsers: ColumnDefinition<AccountUsersTableRowData<User>>[] = [
  {
    accessorKey: 'name',
    header: 'User name',
    Cell: ({ row }) => extractUserName(row.original.data),
    filterFn: (row, _, filterValue) => {
      return extractUserName(row.original.data).toLowerCase().includes(filterValue.toLowerCase());
    }
  },
  {
    accessorKey: 'email',
    header: 'User email',
    size: GENERIC_EMAIL_COLUMN_SIZE,
    Cell: ({ row }) => {
      return row.original.email;
    },
  },
  {
    accessorKey: 'role',
    header: 'User role',
    size: 300,
    Cell: ({ row }) => {
      return AccountRolesDetails.find(accountRole => accountRole.role === row.original.accountRole)?.label
    }
  },
  {
    accessorKey: 'status',
    header: 'User status',
    Cell: ({ row }) => <UserStatusChip status={row.original.status as any} />
  },
];
