import type { ProcessDTO } from '@energywebfoundation/generic-green-proofs-api-client';
import type { ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import { MaybeRestricted, ProcessIdCell } from '@energywebfoundation/generic-green-proofs-ui';
import { Link } from '@mui/material';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { UnrestrictedBlockId } from './BlockId';


export const unitsHistory: ColumnDefinition<ProcessDTO>[] = [
  {
    header: 'Id',
    accessorKey: 'id',
    Cell: ProcessIdCell
  },
  {
    accessorKey: 'computed.volume',
    Header: () => {
      return <>
        Volume
      </>
    },
    header: 'Volume',
  },
  {
    accessorKey: 'computed.retiredAt',
    header: 'Retired at',
    Cell: ({ cell }) => {
      return dayjs(cell.getValue<Date>()).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    accessorKey: 'computed.status',
    header: 'Status',
    Cell: ({ cell }) => {
      return <Chip
        sx={{
          backgroundColor: 'rgba(24, 128, 56, 0.20)',
          color: '#188038',
          paddingLeft: 0,
          textTransform: 'capitalize'
        }}
        label={`${cell.getValue<string>()}`}
      />;
    },
  },
  {
    accessorKey: 'computed.processUrl',
    header: 'Public URL',
    Cell: ({ cell }) => {
      const value = cell.getValue<MaybeRestricted<string>>();

      if (value) {
        return value.isRestricted ? '<restricted>' : (<Link href={value.toString()} target='_blank'>Link</Link>);
      } else {
        return '';
      }
    },
  },
  {
    accessorKey: 'computed.usedUnits',
    header: 'Votings',
    Cell: ({ cell }) => {
      const value = cell.getValue<{ id: string, voting: any }[]>();

      return (
        <>
          {value.map(value => (
            <UnrestrictedBlockId blockId={value.id} voting={value.voting} />
          ))}
        </>
      )
    },
  },
];
