import {
  Country,
  Input,
  Textarea,
} from '@energywebfoundation/generic-green-proofs-config';
import {
  JSONEntityIAM,
  JSONSchema,
} from '@energywebfoundation/generic-green-proofs-ui';
import { commonInputOptions } from '../user/schema';

export const companyJSONSchema = {
  $schema: 'https://json-schema.org/draft/2019-09/schema',
  type: 'object',
  definitions: {
    country: {
      enum: [
        'US',
        'AF',
        'AX',
        'AL',
        'DZ',
        'AS',
        'AD',
        'AO',
        'AI',
        'AQ',
        'AG',
        'AR',
        'AM',
        'AW',
        'AU',
        'AT',
        'AZ',
        'BH',
        'BS',
        'BD',
        'BB',
        'BY',
        'BE',
        'BZ',
        'BJ',
        'BM',
        'BT',
        'BO',
        'BQ',
        'BA',
        'BW',
        'BV',
        'BR',
        'IO',
        'BN',
        'BG',
        'BF',
        'BI',
        'KH',
        'CM',
        'CA',
        'CV',
        'KY',
        'CF',
        'TD',
        'CL',
        'CN',
        'CX',
        'CC',
        'CO',
        'KM',
        'CG',
        'CD',
        'CK',
        'CR',
        'CI',
        'HR',
        'CU',
        'CW',
        'CY',
        'CZ',
        'DK',
        'DJ',
        'DM',
        'DO',
        'EC',
        'EG',
        'SV',
        'GQ',
        'ER',
        'EE',
        'ET',
        'FK',
        'FO',
        'FJ',
        'FI',
        'FR',
        'GF',
        'PF',
        'TF',
        'GA',
        'GM',
        'GE',
        'DE',
        'GH',
        'GI',
        'GR',
        'GL',
        'GD',
        'GP',
        'GU',
        'GT',
        'GG',
        'GN',
        'GW',
        'GY',
        'HT',
        'HM',
        'VA',
        'HN',
        'HK',
        'HU',
        'IS',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IE',
        'IM',
        'IL',
        'IT',
        'JM',
        'JP',
        'JE',
        'JO',
        'KZ',
        'KE',
        'KI',
        'KP',
        'KR',
        'KW',
        'KG',
        'LA',
        'LV',
        'LB',
        'LS',
        'LR',
        'LY',
        'LI',
        'LT',
        'LU',
        'MO',
        'MK',
        'MG',
        'MW',
        'MY',
        'MV',
        'ML',
        'MT',
        'MH',
        'MQ',
        'MR',
        'MU',
        'YT',
        'MX',
        'FM',
        'MD',
        'MC',
        'MN',
        'ME',
        'MS',
        'MA',
        'MZ',
        'MM',
        'NA',
        'NR',
        'NP',
        'NL',
        'NC',
        'NZ',
        'NI',
        'NE',
        'NG',
        'NU',
        'NF',
        'MP',
        'NO',
        'OM',
        'PK',
        'PW',
        'PS',
        'PA',
        'PG',
        'PY',
        'PE',
        'PH',
        'PN',
        'PL',
        'PT',
        'PR',
        'QA',
        'RE',
        'RO',
        'RU',
        'RW',
        'BL',
        'SH',
        'KN',
        'LC',
        'MF',
        'PM',
        'VC',
        'WS',
        'SM',
        'ST',
        'SA',
        'SN',
        'RS',
        'SC',
        'SL',
        'SG',
        'SX',
        'SK',
        'SI',
        'SB',
        'SO',
        'ZA',
        'GS',
        'SS',
        'ES',
        'LK',
        'SD',
        'SR',
        'SJ',
        'SZ',
        'SE',
        'CH',
        'SY',
        'TW',
        'TJ',
        'TZ',
        'TH',
        'TL',
        'TG',
        'TK',
        'TO',
        'TT',
        'TN',
        'TR',
        'TM',
        'TC',
        'TV',
        'UG',
        'UA',
        'AE',
        'GB',
        'UM',
        'UY',
        'UZ',
        'VU',
        'VE',
        'VN',
        'VG',
        'VI',
        'WF',
        'EH',
        'YE',
        'ZM',
        'ZW',
      ],
    },
  },
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    address: {
      type: 'string',
      minLength: 1,
      maxLength: 128,
    },
    address2: {
      type: 'string',
      maxLength: 128,
    },
    city: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    state: {
      type: 'string',
      maxLength: 64,
    },
    postcode: {
      type: 'string',
      minLength: 1,
      maxLength: 16,
    },
    country: {
      $ref: '#/definitions/country',
    },
    website: {
      type: 'string',
      maxLength: 128,
    },
    email: {
      type: 'string',
      format: 'email',
      maxLength: 64,
    },
    externalId: {
      type: 'string',
    }
  },
  required: [
    'name',
    'address',
    'city',
    'postcode',
    'country',
    'website',
    'email',
    'externalId'
  ],
  additionalProperties: false,
} as const satisfies JSONSchema;

export const companyUISchema: JSONEntityIAM['getUISchema'] = () => ({
  name: Input({
    title: 'Company name',
    placeholder: 'e.g. Acme Co.',
    options: commonInputOptions,
  }),
  city: Input({
    title: 'City',
    placeholder: 'City',
    options: commonInputOptions,
  }),
  state: Input({
    title: 'Region or state',
    placeholder: 'Region or state',
    options: commonInputOptions,
  }),
  postcode: Input({
    title: 'Postal code',
    placeholder: 'Postal code',
    options: commonInputOptions,
  }),
  country: Country({
    title: 'Country',
    placeholder: 'Select Country',
    options: commonInputOptions,
  }),
  address: Textarea({
    title: 'Company address line 1',
    placeholder: 'e.g. 81 Anna Street',
    options: commonInputOptions,
  }),
  address2: Textarea({
    title: 'Company address line 2',
    placeholder: 'e.g. 81 Anna Street',
    options: commonInputOptions,
  }),
  website: Input({
    title: 'Company website',
    placeholder: 'e.g. www.example.com',
    options: commonInputOptions,
  }),
  email: Input({
    title: 'Company email address',
    placeholder: 'contact@company.com',
    options: commonInputOptions,
  }),
  externalId: Input({
    title: 'Company external ID used for integration',
    placeholder: '',
    options: commonInputOptions,
  }),
});

export const CompanyEntity = {
  getSchema: () => companyJSONSchema,
  mapping: {
    name: 'name',
    email: 'email',
  },
  nonUpdatable: [],
  getUISchema: companyUISchema,
};
