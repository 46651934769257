import { Input } from '@energywebfoundation/generic-green-proofs-config';
import {
  JSONEntityIAM,
  JSONSchema,
} from '@energywebfoundation/generic-green-proofs-ui';

export const userJSONSchema = {
  $schema: 'https://json-schema.org/draft/2019-09/schema',
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    middleName: {
      type: 'string',
      maxLength: 64,
    },
    lastName: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    employerName: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    phoneNumber: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    email: {
      type: 'string',
      format: 'email',
      maxLength: 64,
    }
  },
  required: ['firstName', 'lastName', 'employerName', 'phoneNumber', 'email'],
  additionalProperties: false,
} as const satisfies JSONSchema;

export const commonInputOptions = {
  noDebounce: true,
  fieldProps: {
    labelGridProps: { xs: 4 },
    inputGridProps: { xs: 8, mb: 2 },
  },
};

const userUISchema: JSONEntityIAM['getUISchema'] = () => ({
  firstName: Input({
    title: 'First Name',
    placeholder: 'First Name',
    options: commonInputOptions,
  }),
  middleName: Input({
    title: 'Middle Name',
    placeholder: 'Middle Name',
    options: commonInputOptions,
  }),
  lastName: Input({
    title: 'Last Name',
    placeholder: 'Last Name',
    options: commonInputOptions,
  }),
  employerName: Input({
    title: 'Employer name',
    placeholder: 'e.g. Acme Co.',
    options: commonInputOptions,
  }),
  phoneNumber: Input({
    title: 'Phone number',
    placeholder: 'Phone number',
    options: commonInputOptions,
  }),
  email: Input({
    title: 'Email address',
    placeholder: 'Email address',
    options: commonInputOptions,
  }),
});

export const UserEntity = {
  getSchema: () => userJSONSchema,
  getUISchema: userUISchema,
  mapping: {
    email: 'email',
  },
  nonUpdatable: [],
};
