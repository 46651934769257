import {
  Country,
  Hidden,
  Input,
  Textarea,
} from '@energywebfoundation/generic-green-proofs-config';
import { Configuration } from '@energywebfoundation/generic-green-proofs-ui';
import { AccountEntity } from './account/schema';
import { CompanyEntity } from './company/schema';
import { UnitEntity } from './unit/schema';
import { UserEntity, commonInputOptions } from './user/schema';

const commonOptions = {
  noDebounce: true,
};

export const ManifestConfiguration: Configuration['manifest'] = {
  Entities: {
    Account: AccountEntity,
    Company: CompanyEntity,
    Unit: UnitEntity,
    User: UserEntity,
    Onboarding: {
      mapping: {},
      nonUpdatable: [],
      getSchema: () => ({
        $schema: 'https://json-schema.org/draft/2019-09/schema',
        type: 'object',
        definitions: {
          ...CompanyEntity.getSchema().definitions,
        },
        properties: {
          user: UserEntity.getSchema(),
          company: CompanyEntity.getSchema(),
        },
        required: ['user', 'company'],
      } as const),
      getUISchema: () => ({
        'ui:field': 'wizard',
        'ui:style': {
          // TODO: not valid type
          // marginTop: 60,
        },
        user: {
          firstName: Input({
            title: 'First Name',
            placeholder: 'First Name',
            options: commonOptions,
          }),
          middleName: Input({
            title: 'Middle Name',
            placeholder: 'Middle Name',
            options: commonOptions,
          }),
          lastName: Input({
            title: 'Last Name',
            placeholder: 'Last Name',
            options: commonOptions,
          }),
          employerName: Input({
            title: 'Employer name',
            placeholder: 'e.g. Acme Co.',
            options: commonInputOptions,
          }),
          phoneNumber: Input({
            title: 'Phone number',
            placeholder: 'Phone number',
            options: commonInputOptions,
          }),
          email: Hidden(),
        },
        company: {
          name: Input({
            title: 'Company name',
            placeholder: 'e.g. Acme Co.',
            options: commonInputOptions,
          }),
          city: Input({
            title: 'City',
            placeholder: 'City',
            options: commonOptions,
          }),
          state: Input({
            title: 'Region or state',
            placeholder: 'Region or state',
            options: commonOptions,
          }),
          postcode: Input({
            title: 'Postal code',
            placeholder: 'Postal code',
            options: commonOptions,
          }),
          country: Country({
            title: 'Country',
            placeholder: 'Select Country',
            options: commonOptions,
          }),
          address: Textarea({
            title: 'Company address line 1',
            placeholder: 'e.g. 81 Anna Street',
            options: commonInputOptions,
          }),
          address2: Textarea({
            title: 'Company address line 2',
            placeholder: 'e.g. 81 Anna Street',
            options: commonInputOptions,
          }),
          website: Input({
            title: 'Company website',
            placeholder: 'e.g. www.example.com',
            options: commonInputOptions,
          }),
          email: Input({
            title: 'Company email',
            placeholder: 'contact@company.com',
            options: commonInputOptions,
          }),
        },
        'ui:options': {
          stepper: {
            variant: 'secondary',
          },
          steps: [
            {
              name: 'Personal Info',
              fields: [
                {
                  title: 'Personal information',
                  parent: 'user',
                  items: [
                    {
                      name: 'firstName',
                      $ref: '#/properties/user/properties/firstName',
                    },
                    {
                      name: 'middleName',
                      $ref: '#/properties/user/properties/middleName',
                    },
                    {
                      name: 'lastName',
                      $ref: '#/properties/user/properties/lastName',
                    },
                  ],
                  uiSchema: {
                    'ui:field': 'group',
                    'ui:title': 'Name',
                    'ui:options': {
                      fieldProps: {
                        labelGridProps: {
                          xs: 4,
                        },
                        inputGridProps: {
                          mb: 2.4,
                          xs: 8,
                        },
                        inputGridItemProps: {
                          xs: 4,
                          mb: 1,
                        },
                      },
                    },
                  },
                },
                {
                  parent: 'user',
                  items: [
                    {
                      name: 'employerName',
                      $ref: '#/properties/user/properties/employerName',
                    },
                    {
                      name: 'phoneNumber',
                      $ref: '#/properties/user/properties/phoneNumber',
                    },
                    {
                      name: 'email',
                      $ref: '#/properties/user/properties/email',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Company information',
              fields: [
                {
                  title: 'Company information',
                  parent: 'company',
                  items: [
                    {
                      name: 'name',
                      $ref: '#/properties/company/properties/name',
                    },
                  ],
                },
                {
                  parent: 'company',
                  items: [
                    {
                      name: 'city',
                      $ref: '#/properties/company/properties/city',
                    },
                    {
                      name: 'state',
                      $ref: '#/properties/company/properties/state',
                    },
                    {
                      name: 'country',
                      $ref: '#/properties/company/properties/country',
                    },
                    {
                      name: 'postcode',
                      $ref: '#/properties/company/properties/postcode',
                    },
                  ],
                  uiSchema: {
                    'ui:field': 'group',
                    'ui:title': 'Company address',
                    'ui:options': {
                      fieldProps: {
                        labelGridProps: {
                          xs: 4,
                        },
                        inputGridProps: {
                          mb: 2.4,
                          xs: 8,
                        },
                        inputGridItemProps: {
                          xs: 4,
                          mb: 1,
                        },
                      },
                    },
                  },
                },
                {
                  parent: 'company',
                  items: [
                    {
                      name: 'address',
                      $ref: '#/properties/company/properties/address',
                    },
                    {
                      name: 'address2',
                      $ref: '#/properties/company/properties/address2',
                    },
                    {
                      name: 'website',
                      $ref: '#/properties/company/properties/website',
                    },
                    {
                      name: 'email',
                      $ref: '#/properties/company/properties/email',
                    },
                  ],
                },
              ],
            },
          ],
        },
      }),
    },
  },
  System: {},
};
