import dayjs from 'dayjs';
import {
  ActionButton,
  camelCaseToWords,
  ColumnDefinition,
  matchBySome,
  NameAndIdCell,
  TextFilter,
} from '@energywebfoundation/generic-green-proofs-ui';
import Box from '@mui/material/Box';
import type { ProcessDTO } from '@energywebfoundation/generic-green-proofs-api-client';

export const actions: ColumnDefinition<ProcessDTO>[] = [
  {
    header: 'ID',
    Filter: TextFilter,
    filterFn: (row, _: string, filterValue: string) =>
      matchBySome([row.original.id, row.original.type], filterValue),
    Cell: ({ row }) => {
      return (
        <NameAndIdCell
          name={camelCaseToWords(row.original.type)}
          id={row.original.id}
        />
      );
    },
  },
  {
    header: 'Account Name',
    accessorKey: 'computed.accountName',
  },
  {
    header: 'Account Type',
    accessorKey: 'computed.accountType',
  },
  {
    header: 'Created At',
    accessorFn: (row) => dayjs(row.createdAt).format('DD-MM-YYYY HH:mm:ss'),
  },
  {
    header: 'Actions',
    size: 100,
    accessorKey: 'actions',
    Cell: ({ row }) => {
      return (
        <Box display="flex" gap={2} onClick={(e) => e.stopPropagation()}>
          {row.original.actions.map((action) => (
            <ActionButton
              process={row.original}
              action={action}
              key={row.original.id}
            />
          ))}
        </Box>
      );
    },
  },
];
