import { ConfigurationPack } from '@energywebfoundation/generic-green-proofs-api';
import {
  FileAPI,
  Hidden,
  Input,
  NumberInput,
} from '@energywebfoundation/generic-green-proofs-config';
import { JSONSchema } from '@energywebfoundation/generic-green-proofs-ui';
import { AccountType } from '../account/types';

export const ONE_MB = 1024 * 1024;

export const getAccountTypeFromContext = (context: any): AccountType => {
  return context.account.data.type;
};


export const fileOptions = {
  style: {
    marginBottom: 24,
    marginTop: 10,
  },
  maxSize: 10 * ONE_MB,
};


export const getUISchema: ConfigurationPack['manifest']['Entities']['Unit']['getUISchema'] =
  (context) => {
    const { uiSchemaType } = context;

    if (uiSchemaType === 'create') {
      return {
        ...unitUISchema,
      };
    }
    if (uiSchemaType === 'edit') {
      return {
        ...unitUISchema,
        'ui:buttonsWrapperStyle': {
          mt: 4,
        },
      };
    }

    if (uiSchemaType === 'view') {
      return {
        ...unitUISchema,
      };
    }
    return unitUISchema;
  };

export const unitJSONSchema = {
  $schema: 'https://json-schema.org/draft/2019-09/schema',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    id: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    certificate: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    volume: {
      type: 'number',
      minimum: 0,
    },
    chargingId: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    chargingTimestampEnd: {
      type: 'string',
      format: 'date-time',
    }
  },
  required: ['name', 'id', 'certificate', 'volume'],
  additionalProperties: false,
} as const satisfies JSONSchema;

export const unitUISchema = {
  name: Input({ title: 'Unit Name', placeholder: 'Certificate Name' }),
  id: Input({ title: 'Unit ID', placeholder: 'Certificate ID' }),
  certificate: FileAPI({ title: 'Certificate', placeholder: 'Certificate', ...fileOptions }),
  volume: NumberInput({ title: 'Volume', placeholder: 'Volume' }),
  chargingSessionId: Hidden(),
  chargingLocation: {
    latitude: Hidden(),
    longitude: Hidden(),
  },
  chargingTimestampEnd: Hidden(),
};

export const getSchema = () => unitJSONSchema;

export const UnitEntity = {
  getSchema,
  getUISchema,
  mapping: {
    volume: 'volume',
  },
  nonUpdatable: [],
};
