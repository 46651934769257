import { JSONSchema } from '@energywebfoundation/generic-green-proofs-ui';
import { FromSchema } from "json-schema-to-ts";

export const AccountRoles = {
  fullAccess: 'fullAccess',
  readOnly: 'readOnly',
} as const;

export const accountRoles = {
  schema: {
    $schema: 'https://json-schema.org/draft/2019-09/schema',
    type: 'string',
    enum: [
      AccountRoles.fullAccess,
      AccountRoles.readOnly,
    ],
  } as const satisfies JSONSchema,
  default: AccountRoles.fullAccess,
}

export type AccountRole = FromSchema<typeof accountRoles['schema']>;

export const AccountRolesDetails: {label: string; role: AccountRole, description: string}[] = [
  {
    label: 'Initiate and approve',
    role: AccountRoles.fullAccess,
    description: 'Can initiate, sign, and accept a transaction.',
  },
  {
    label: 'Read only',
    role: AccountRoles.readOnly,
    description: 'Cannot interact \n with units.',
  },
];

export const isApprover = (membership: AccountRole | string) => {
  return membership === AccountRoles.fullAccess;
};
