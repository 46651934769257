import { Configuration } from "@energywebfoundation/generic-green-proofs-ui";
import CargoOwnerIcon from '../../frontend/assets/cargoOwnerIcon.svg?url';

export const AccountTypesDetails: Configuration['AccountTypesDetails'] = [
  {
    name: 'General Account',
    description: 'You are owner of the cargo during voyage.',
    type: 'General',
    icon: CargoOwnerIcon,
  },
];
