import type { AccountsTableRowData, ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import {
  AccountNameCell,
  AccountStatusCell,
  AccountStatusFilters,
  AccountTypeCell,
  NameWithIcon,
} from '@energywebfoundation/generic-green-proofs-ui';
import { AccountTypeFilters } from './account-type.filter';
import { Account } from '../../../entities/account/types'

export const accounts: ColumnDefinition<AccountsTableRowData<Account>>[] = [
  {
    accessorKey: 'data.name',
    header: 'Account name',
    Cell: AccountNameCell,
  },
  {
    accessorKey: 'data.type',
    header: 'Account type',
    Cell: AccountTypeCell,
    ...AccountTypeFilters,
  },
  {
    accessorKey: 'company',
    header: 'Company',
    Cell: ({ row }) => {
      return (
        <NameWithIcon name={row.original.companyName} />
      );
    },
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Account status',
    Cell: AccountStatusCell,
    ...AccountStatusFilters,
  },
];
