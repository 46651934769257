import { FromSchema } from "json-schema-to-ts";
import { JSONSchema } from '@energywebfoundation/generic-green-proofs-ui';

export const companyRoles = {
  schema: {
    $schema: 'https://json-schema.org/draft/2019-09/schema',
    type: 'string',
    enum: ['regular', 'companyRepresentative'],
  } as const satisfies JSONSchema,
  default: 'regular',
};

export type CompanyRole = FromSchema<typeof companyRoles['schema']>;

export const CompanyRoles = {
  regular: 'regular',
  companyRepresentative: 'companyRepresentative',
} as const


export const CompanyRolesDetails: { label: string, role: CompanyRole, description: string }[] = [
  {
    label: 'Company Representative',
    role: CompanyRoles.companyRepresentative,
    description: 'Company Representative',
  },
  {
    label: 'Regular',
    role: CompanyRoles.regular,
    description: 'Regular company member.',
  },
];

export const companyRolesActions: Record<CompanyRole, {
  companyRemovalNotify: boolean,
  minimalMembershipsPerCompany: number,
  closeCompanyMembershipNotify: boolean
}>  = {
  [CompanyRoles.regular]: {
    companyRemovalNotify: false,
    minimalMembershipsPerCompany: 0,
    closeCompanyMembershipNotify: false
  },
  [CompanyRoles.companyRepresentative]: {
    companyRemovalNotify: true,
    minimalMembershipsPerCompany: 1,
    closeCompanyMembershipNotify: true
  },
}
