import type { InvoicesTableRowData, ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import { formatCurrency } from '@energywebfoundation/generic-green-proofs-ui';

export const invoices: ColumnDefinition<InvoicesTableRowData>[] = [
  {
    header: 'Type',
    accessorKey: 'type',
  },
  {
    header: 'Description',
    accessorKey: 'description',
  },
  {
    header: 'Quantity',
    accessorKey: 'volume',
    accessorFn: (row) => row.quantity,
  },
  {
    header: 'Price',
    accessorKey: 'price',
    accessorFn: (row) => formatCurrency(row.price),
  },
];
