import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

export const Container = styled(Box)({
  margin: '0 auto',
  height: '100svh',
});

export const TopBarSpacer = styled(Box)(({ theme }) => ({
  display: 'none',
  height: '95px',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '30px',
  paddingBottom: '60px',
  paddingLeft: '70px',
  paddingRight: '70px',
  width: '100%',
  transition: 'background .2s ease',
  [theme.breakpoints.down('sm')]: {
    padding: '0 16px',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
}));

export const NavButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '40px',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const Masthead = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: 'top 100% left 65%',
  },
}));

const textShine = keyframes`
    0% {
      background-position: 10% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
`;

export const HeadlineGradient = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  lineHeight: '72.61px',
  fontWeight: 700,
  background:
    `linear-gradient(90deg, #124652 28.82%, ${theme.palette.primary.main} 68%, #000000 122.97%)`,
  '-webkit-background-clip': 'text',
  'background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  'text-fill-color': 'transparent',
  'background-size': '500% auto',
  animation: `${textShine} 8s ease-in-out infinite alternate`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '44px',
    lineHeight: 'normal',
  },
}));

export const GradientWrapper = styled(Box)(() => ({
  backgroundImage:
    'linear-gradient(rgba(243, 248, 248, 1) rgba(217, 217, 217, 0))',
}));

export const HeroContainer = styled(Box)(({ theme }) => ({
  padding: '0 64px',
  position: "absolute",
  top: '50%',
  transform: 'translateY(-50%)',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export const RegistryExplainerStyled = styled('picture')(({ theme }) => ({
  '& > *': {
    height: 'unset',
    width: 'calc(100% - 80px)',
    marginTop: 0,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      width: '100%',
    },
  },
}));

export const LogoLink = styled('a')(() => ({
  textTransform: 'unset',
  textDecoration: 'unset',
}));
