import {
  JSONEntityIAM,
  JSONSchema,
} from '@energywebfoundation/generic-green-proofs-ui';
import {
  Input,
  Hidden,
} from '@energywebfoundation/generic-green-proofs-config';

export const accountJSONSchema = {
  $schema: 'https://json-schema.org/draft/2019-09/schema',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 64,
    },
    type: {
      enum: ['General'],
      type: 'string',
      minLength: 1,
    },
  },
  required: ['type', 'name'],
  additionalProperties: false,
} as const satisfies JSONSchema;

const accountUISchema: JSONEntityIAM['getUISchema'] = () => ({
  'ui:buttonsWrapperStyle': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 74,
    zIndex: 2,
    maxWidth: 'md',
    margin: '0 auto',
    alignItems: 'center',
  },
  type: Hidden(),
  name: Input({ title: 'Account Name', placeholder: 'Account Name' }),
});

export const AccountEntity = {
  getSchema: () => accountJSONSchema,
  getUISchema: accountUISchema,
  mapping: {
    name: 'name',
    type: 'type',
  },
  nonUpdatable: [],
};
