import {
  GENERIC_EMAIL_COLUMN_SIZE,
  GENERIC_NAME_COLUMN_SIZE,
  UUID_COLUMN_SIZE,
} from '../column.sizes';
import type { UsersTableRowData, ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import {
  UserAccountsCell,
  UserEmailCell,
  NameWithIcon,
  UsersCompanyNameCell,
  UserStatusCell,
  UserStatusFilters
} from '@energywebfoundation/generic-green-proofs-ui';
import type { User } from '../../../entities/user/types';
import { extractUserName } from '../../../entities/user/utils';

export const users: ColumnDefinition<UsersTableRowData<User>>[] = [
  {
    accessorKey: 'name',
    header: 'User name',
    size: GENERIC_NAME_COLUMN_SIZE,
    Cell: ({ row }) => <NameWithIcon name={extractUserName(row.original.data)} />,
    filterFn: (row, _, filterValue) => {
      return extractUserName(row.original.data).toLowerCase().includes(filterValue.toLowerCase());
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    size: GENERIC_EMAIL_COLUMN_SIZE,
    Cell: UserEmailCell,
  },
  {
    accessorKey: 'company',
    header: 'Company name and ID',
    size: UUID_COLUMN_SIZE,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    Cell: UsersCompanyNameCell,
  },
  {
    accessorKey: 'accounts',
    header: 'Account and role',
    size: 300,
    muiTableBodyCellProps: {
      sx: { height: 69, padding: '10px 10px 10px 16px' },
    },
    Cell: UserAccountsCell,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 170,
    Cell: UserStatusCell,
    ...UserStatusFilters,
  },
];
