import { MultiSelectFilter } from '@energywebfoundation/generic-green-proofs-ui';
import { AccountTypesDetails } from '../../../entities/account/config';

export const AccountTypeFilters = {
  Filter: MultiSelectFilter,
  filterSelectOptions: AccountTypesDetails.map((ad) => ({
    value: ad.type,
    text: ad.name,
  })),
  filterFn: (row: any, _columnId: string, filterValue: string) => {
    return filterValue.includes(row.original.data.type);
  },
};
