import type { Configuration } from '@energywebfoundation/generic-green-proofs-ui';
import { units } from './units/units.table';
import { companies } from './companies/companies.table';
import { users } from './users/users.table';
import { invoices } from './billing/invoice.table';
import { billings } from './billing/invoices.table';
import { raBillings } from './billing/ra-invoices.table';
import { logs } from './logs/logs.table';
import { accountUsers } from './account/account-users.table';
import { accounts } from './account/accounts.table';
import { companyUsers } from './companies/company-users.table';
import { actions } from './actions/actions.table';
import { unitsHistory } from './units/units-history.table';
import { pendingUnitsTable } from './units/pending-units.table';

export const tables: Configuration['tables'] = {
  accounts: { columns: accounts },
  accountUsers: { columns: accountUsers },
  billings: { columns: billings },
  raBillings: { columns: raBillings },
  invoices: { columns: invoices },
  logs: { columns: logs },
  companies: { columns: companies },
  companyUsers: { columns: companyUsers },
  currentUnits: { columns: units },
  unitsHistory: { columns: unitsHistory },
  pendingUnits: pendingUnitsTable,
  users: { columns: users },
  actions: { columns: actions },
};
