import type { CompaniesTableRowData, ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import {
  CompanyNameCell,
  CompanyStatusCell,
  CompanyStatusFilters,
  NameWithIcon,
  UserStatusChip
} from '@energywebfoundation/generic-green-proofs-ui';

import type { Company } from '../../../entities/company/types';

export const companies: ColumnDefinition<CompaniesTableRowData<Company>>[] = [
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Company Name',
    Cell: CompanyNameCell,
  },
  {
    accessorKey: 'status',
    header: 'Company Status',
    Cell: CompanyStatusCell,
    ...CompanyStatusFilters,
  },
  {
    accessorKey: 'users',
    header: 'Nominated CR',
    size: 285,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    Cell: (props) => {
      const nominatedCr = props.row.original.users.length ? props.row.original.users[0] : null;
      const name = nominatedCr ? `${nominatedCr.data.firstName} ${nominatedCr.data.lastName}` : 'Not assigned';
      return (<NameWithIcon name={name} />);
    },
  },
  {
    accessorKey: 'users[0].status',
    header: 'CR Status',
    muiTableBodyCellProps: {
      sx: { height: 80, padding: '10px 10px 10px 16px' },
    },
    Cell: ({ row }) => {
      const status = row.original.users.length ? row.original.users[0].status : null;
      return <UserStatusChip status={status as any} />;
    },
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];
