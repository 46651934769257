import Box from '@mui/material/Box';
import {
  Container,
  HeadlineGradient,
  HeroContainer,
  LogoLink,
  Masthead,
  NavButtonsContainer,
  NavContainer,
  TopBarSpacer,
} from './CustomHomepage.style';
import { Button } from './components/Button';
import type { CustomHomePageProps } from '@energywebfoundation/generic-green-proofs-ui';
import Video from '../../assets/video_car.mp4?url';
import MaritimeLogo from '../../assets/logo-dark.svg?url';

export function CustomHomepage(props: CustomHomePageProps) {
  const { logout, isAuthenticated, defaultLoggedPage, login, navigate } = props;
  return (
    <Container position={'relative'}>
      <Box position={'absolute'} zIndex={-1} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} overflow={'hidden'}>
        <video src={Video} autoPlay loop muted style={{ position: "fixed", top: "0", left: "0", minWidth: "100%", minHeight: "100%", objectFit: 'fill' }} />
      </Box>
      <Masthead>
        <NavContainer>
          <LogoLink href="/">
            <Box display="flex" alignContent="center" pt={0.5}>
              <img src={MaritimeLogo} alt="logo" />
            </Box>
          </LogoLink>
          <NavButtonsContainer>
          </NavButtonsContainer>
          <Box display="flex" alignItems="center" gap="15px" justifyContent={'center'}>
            {isAuthenticated && (
              <Button
                variant="contained"
                color='primary'
                onClick={() => navigate(defaultLoggedPage)}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                  color: '#fff',
                })}
              >
                Explore Registry
              </Button>
            )}
            <Button
              variant="outlined"
              color='primary'
              sx={{
                backgroundColor: '#fff',
              }}
              onClick={isAuthenticated ? logout : login}
            >
              {isAuthenticated ? 'Logout' : 'Login'}
            </Button>

          </Box>
        </NavContainer>
        <TopBarSpacer />
        <HeroContainer display={'flex'} justifyContent={'center'}>
          <Box display={'flex'} justifyContent={'center'} sx={{
            backgroundColor: '#fff',
            width: 800,
            borderRadius: 10,
            alignContent: 'center',
            marginTop: 20,
            height: '100%',
            padding: '32px 16px'
          }}>
            <HeadlineGradient sx={{
              textAlign: 'center',
              fontSize: 65,
            }}>
              Welcome to the <br /> Green Proofs for EVs
            </HeadlineGradient>
          </Box>
        </HeroContainer>
      </Masthead>
    </Container>
  );
}
