import dayjs from 'dayjs';
import type { BillingsTableRowData, ColumnDefinition } from '@energywebfoundation/generic-green-proofs-ui';
import {
  BillingPdfCell,
  BillingStatusCell,
  formatCurrency,
  NameAndIdCell
} from '@energywebfoundation/generic-green-proofs-ui';

export const billings: ColumnDefinition<BillingsTableRowData>[] = [
  {
    header: 'Invoice ID',
    accessorKey: 'invoiceID',
    Cell: ({ row }) => {
      return <NameAndIdCell id={row.original.id} name={row.original.companyName} />;
    },
  },
  {
    id: 'from',
    header: 'From',
    accessorKey: 'from',
    accessorFn: (row) => dayjs(row.from).format('DD-MM-YYYY HH:mm:ss'),
  },
  {
    header: 'To',
    accessorFn: (row) => dayjs(row.to).format('DD-MM-YYYY HH:mm:ss'),
  },
  {
    header: 'PDF',
    accessorKey: 'pdf',
    Cell: BillingPdfCell,
  },
  {
    header: 'Amount',
    accessorFn: (row) => formatCurrency(row.amount),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    Cell: BillingStatusCell,
  },
];
