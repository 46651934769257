import { alpha, Link, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export const BlockLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.light,
  textDecoration: 'none',
  lineHeight: '24px',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 400,
  textDecorationLine: 'underline',
  textUnderlineOffset: 3,
}));

export const BlockText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  textDecoration: 'none',
  lineHeight: '24px',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 400,
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: alpha(theme.palette.common.black, 0.87),
    maxWidth: 250,
    fontSize: theme.typography.subtitle1.fontSize,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}));
